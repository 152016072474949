<template>
  <div>
    <pwd-contair>
      <div slot="menu">账户变动</div>
      <div slot="pwdContair" style="min-height: 400px">
        <tag-components>
          <div slot="tag">
            <tag-item link="AllRecord" >
              <div slot="tagname">全部记录</div>
            </tag-item>
            <tag-item link="Audit">
              <div slot="tagname">审核中</div>
            </tag-item>
            <tag-item
              link="WithdrawalOf"
            >
              <div slot="tagname">提现中</div>
            </tag-item>
            <tag-item link="ToAccount">
              <div slot="tagname">已到账</div>
            </tag-item>
            <tag-item
              link="WithdrawalFailure"
            >
              <div slot="tagname">提现失败</div>
            </tag-item>
          </div>
          <div slot="search">
            <div class="ssssSborder">
              <div class="ssssSearch">
                <el-input
                  class="searchinput"
                  placeholder="提现金额"
                  @keyup.enter.native="searchInfo()"
                  v-model="search"
                ></el-input>
              </div>
              <div class="searchicon bg_f4f4" @click="searchInfo">
                <i class="el-icon-search co_000"></i>
              </div>
            </div>
          </div>
        </tag-components>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import TagItem from "@/components/tag/tagItem.vue";
import tagComponents from "@/components/tag/tagComponents";

import { eventBus } from "@/config/bus"
export default {
  components: { pwdContair, tagComponents, TagItem },
  data() {
    return {
      search: ''
    }
  },
  methods: {
    searchInfo() {
      eventBus.$emit('searchInfo', this.search)
    }
  }
};
</script>
<style lang="less" scoped>

</style>